/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Dropdown, Menu } from 'antd'
import { FormattedMessage } from 'react-intl';
import GitHubButton from 'react-github-btn'
import './pc.less'
import * as icons from '../../assets/icons'
import { GithubURL, geekLamp, BBSURL, geekcodeCloud,Twitter,Medium } from "../../common";
const Header = ({ changeLag }) => {
  const [acKey, setAcKey] = useState()
  const history = useHistory();
  const locale = sessionStorage.getItem('locale') || window.navigator.language.toLowerCase().slice(0, 2) || 'zh';
  const defText = locale === 'zh' ? 'English' : '中文';
  const [localeLag, setLocaleLag] = useState(defText)
  const [showBg, setShowBg] = useState(false)

  const changeLocale = () => {
    let locale = localeLag === 'English' ? 'en' : 'zh';
    changeLag(locale);
    sessionStorage.setItem('locale', locale);
    setLocaleLag(locale === 'en' ? '中文' : 'English');
  }
  window.onscroll = () => {
    if (Number(window.scrollY) > 10) {
      setShowBg(true)
    } else {
      setShowBg(false)
    }
  }

  const menu = (
    <Menu className="menusDropdown"  >
      <Menu.Item key="geekCode">
        <a className='doc-btn btn-style' target='_blank' rel="noreferrer" href={geekcodeCloud}>
          <span className="cloudIDE" />
          <FormattedMessage id="geekCode" defaultMessage="云IDE" />
        </a>
      </Menu.Item>
      <Menu.Item key="geekLamp">
        <a className='doc-btn btn-style' target='_blank' rel="noreferrer" href={geekLamp}>
          <span className="interview" />
          <FormattedMessage id="geekLamp" defaultMessage="代码面试" />
        </a>
      </Menu.Item>
    </Menu>
  )
  useEffect(() => {
    const pathNameArr = window.location.pathname.split('/')
    if (pathNameArr.length >= 2) {
      setAcKey(`/${pathNameArr[1]}`)
    }
  }, [acKey])

  return (
    <div className='header' style={showBg ? { background: '#2B3262' } : {}}>
      <div className="header-container grid grid-col">
        <div className='header-left'>
          <div className='logo'>
            <img src={icons.logo1} alt="Logo" />
          </div>
          <div className='header-nav'>
            <a className='doc-btn btn-style' target='_blank' rel="noreferrer" href={GithubURL}>Github</a>
            <a className='doc-btn btn-style' target='_blank' rel="noreferrer" href={BBSURL}><FormattedMessage id="docs" defaultMessage="文档" /></a>
            <Dropdown overlay={menu}>
              <a className='doc-btn btn-style' onClick={e => e.preventDefault()}>
                <FormattedMessage id="menuItem3" defaultMessage="快速体验" />
              </a>
            </Dropdown>
            <a className='doc-btn btn-style' target='_self' rel="noreferrer" href="#item7"><FormattedMessage id="pricing" defaultMessage="价格" /></a>
            <a className='doc-btn btn-style' target='_blank' rel="noreferrer" href={Twitter}>Twitter</a>
            <a className='doc-btn btn-style' target='_blank' rel="noreferrer" href={Medium}>Medium</a>
          </div>
        </div>
        <div className='header-right'>
          <GitHubButton href="https://github.com/gcodecloud/geekcode.cloud" data-color-scheme="no-preference: dark_high_contrast; light: dark_high_contrast; dark: dark_high_contrast;" data-size="large" data-show-count="true" aria-label="Star gcodecloud/geekcode.cloud on GitHub">Star</GitHubButton>
          <Button className='changeLocaleBtn' type='text' size='small' onClick={changeLocale}>{localeLag}</Button>
        </div>
        {/* <a className='login-btn' target='_blank' rel="noreferrer" href="/">login in</a> */}
      </div>
    </div>
  )
}
export default Header




