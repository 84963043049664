import React from 'react'
import PriceCard from './PriceCard'
import './index.less'

const PriceText = [
  {
    price: '￥0',
    unit: '/月',
    suffix: '',
    introduce: '开源项目/教育用户',
    introduceColor: '',
    describe: '开始云端编程',
    content: ['1个工作空间', '免费的存储（2GB）', '1 核 vCPU','2G 内存', '镜像管理', '可以协作分享的工作空间']
  },
  {
    price: '￥150',
    unit: '/月',
    suffix: '起',
    introduce: '',
    introduceColor: '专业',
    describe: '开发效率提升',
    content: ['无限的工作空间','跨地区网络速度提升', '5GB+ 存储空间 （每个工作空间）', '4GB+ 内存（每个工作空间）', '2核+ vCPU （每个工作空间）', '至少160小时运行时间', '效率更高的主机']
  },
  {
    price: '￥735',
    unit: '/人/月',
    suffix: '',
    introduce: '',
    introduceColor: '企业',
    describe: '更安全、更有力、私有化部署',
    content: ['无限的工作空间', '团队成员管理', '开发过程审计', '多云跨集群部署', '私有化部署', '跨地区网络速度提升']
  },

]

export default function Price() {
  return (
    <>
      {
        PriceText.map((item, index) => {
          return <PriceCard key={`Price+${index}`} item={item} pId={index} />
        })
      }
    </>
  )
}
