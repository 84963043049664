import React, { useState } from 'react'
import * as icons from '../../assets/icons'
import './index.less'
import { FormattedMessage } from 'react-intl'

// const productListArr = []
const productListArr = [
  {
    icon: icons.prodIcon0,
    title: 'Dev URL',
    desc: '不需要测试机，前后端联调无缝连接',
  },
  {
    icon: icons.prodIcon1,
    title: '即时开发',
    desc: 'git 代码仓库一键生成开发环境',
  },
  {
    icon: icons.prodIcon2,
    title: '提供预编译的模版',
    desc: '多种预制的开发环境，可以直接使用，也可以二次定制，节省配置环境的时间',
  },
  {
    icon: icons.prodIcon3,
    title: '动态加速编译代码',
    desc: '在一个开发环境内动态提高CPU数量，提高Rust/C++编译速度',
  },
]

export default function ProductCharacteristics() {
  const [cardNum, setCardNum] = useState()
  const handleClick = (num) => {
    setCardNum(num)
    console.log('跳转页面')
  }
  return (
    <>
      {productListArr.map((item, index) => {
        return (
          <ProductCard
            key={index + 'product'}
            items={item}
            index={index}
            handleClick={handleClick}
            cardNum={cardNum}
          />
        )
      })}
    </>
  )
}

function ProductCard({ items, index, handleClick, cardNum }) {
  return (
    <div
      className={
        cardNum !== index ? 'product-card-box' : 'product-card-box active'
      }
      onClick={() => handleClick(index)}
    >
      <div className="product-card-content">
        <FormattedMessage id={'item3ItemTitle' + index}>
          {(text) => (
            <>
              <div className="icon-box">
                <img src={items.icon} alt={text} />
              </div>
              <h4>{text}</h4>
            </>
          )}
        </FormattedMessage>
        <p><FormattedMessage id={'item3ItemInfo' + index}/></p>
      </div>
    </div>
  )
}
