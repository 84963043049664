import RouteMob from '../../routes/index-mobile'
import Header from '../Header/Mobile'
import Footer from '../Footer/Mobile'

const MobLayout = ({setLocale,locale}) => {
  return (<div className='container'>
      <Header  changeLag={setLocale}  />
      <div className='container-center'>
      <RouteMob locale={locale} />
      <Footer />
      </div>
      </div>)
}
export default MobLayout