import React, { useEffect, useRef, useState } from "react";
import * as icons from "../../assets/icons";
import video_zh from "../../assets/video/geekcode_cloud_zh.mp4";
import video_en from "../../assets/video/geekcode_cloud_en.mp4";
import "./index.less";


export default function Video({ locale }) {
  const [local, setLocal] = useState(locale);
  const [isPlay, setIsPlay] = useState(false); //视频是否播放，图标是否显示
  const videoRef = useRef();
  const eventPool = {
    //视频播放与暂停
    videoPlayChange: () => {
      const video = videoRef.current;
      if (video.paused) {
        //点击视频的时候如果暂停状态，则让其播放
        video.play();
        setIsPlay(true);
      } else {
        video.pause();
        setIsPlay(false);
      }
    },

    // 视频播放结束返回初始状态
    videoPlayEnd: () => {
      const video = videoRef.current;
      const videoSrc = video.currentSrc;
      video.src = "";
      video.src = videoSrc;
      setIsPlay(false);
    },
  };

  useEffect(() => {
    setLocal(locale);
  }, [locale]);

  return (<div className="video-wrapper">
        <video
          onClick={eventPool.videoPlayChange}
          onEnded={eventPool.videoPlayEnd}
          ref={videoRef}
          preload="auto"
          poster={local === "en" ? icons.videoPoster_en : icons.videoPoster_zh}
        >
          <source src={local === "en" ? video_en : video_zh} />
        </video>
        <div
          className="play-btn"
          onClick={eventPool.videoPlayChange}
          style={{ display: isPlay ? "none" : "block" }}
        >
          <img alt="play" src={icons.videoIcon} />
        </div>
      </div>
  );
}
