import React from 'react'
import RoutePC from '../../routes/index-pc'
import Header from '../Header/Pc'
import Footer from '../Footer/Pc'

const PCLayout = ({ setLocale, locale }) => {
  return (
    <div className='container'>
      <Header changeLag={setLocale} />
      <div className='container-center'>
        <RoutePC locale={locale} />
        <Footer />
      </div>
    </div>
  )
}
export default PCLayout;