// eslint-disable-next-line import/no-anonymous-default-export
export default {
  geekLamp: "代码面试",
  geekCode: "云IDE",
  pricing: "价格",
  discussions: "论坛",
  docs: "文档",
  menuItem3: "快速体验",
  headDesc: "GeekCode.Cloud 云开发环境",
  headDesc1: "让软件工程师们一起",
  freeTrialBtn: "免费体验",
  contactUsBtn: "联系我们",
  reserveBtn: "开始使用",
  headItem0: "远程开发",
  headItem1: "联调应用",
  headItem2: "分享环境",
  headItem3: "测试程序",
  headItem4: "审核代码",
  item3Title: "此网站在GeekCode上完成开发和测试",
  item3SubTitle: "支持私有化部署和API等多种部署方式，打通开发环节上下游",
  item3ItemTitle0: "Dev URL",
  item3ItemInfo0: "不需要测试机，前后端联调无缝连接",
  item3ItemTitle1: "即时开发",
  item3ItemInfo1: "git 代码仓库一键生成开发环境",
  item3ItemTitle2: "提供预编译的模版",
  item3ItemInfo2: "多种预制的开发环境，可以直接使用，也可以二次定制，节省配置环境的时间",
  item3ItemTitle3: "动态加速编译代码",
  item3ItemInfo3: "在一个开发环境内动态提高CPU数量，提高Rust/C++编译速度",
  item4Title: "为什么选择",
  carouse0: "git 代码仓库一键生成开发环境",
  carouse1: "编译加速，写代码更流畅",
  carouse2: "Dev.URL 让团队工作更高效",
  carouse3: "支持多个应用",
  carouse4: "即开即用",
  // carouse4: "同时处理多项任务",
  // carouse5: "开发环境由团队统一管理",
  item5Title: "使用场景",
  item5SubTitle: "解决研发团队在开发与自测等环节下的运行环境复杂性",
  item5ListTitle0: "项目依赖多个环境",
  item5ListInfo0: "当开发过程中涉及多个依赖的运行环境（x86,arm架构等）不需要频繁切换term,在不同环境下编译你的binary",
  item5ListTitle1: "前后端工程师无缝沟通",
  item5ListInfo1: "Dev URL 开发中不再需要测试服务器，远程协同开发无缝连接",
  item5ListTitle2: "自测时管理公有云token",
  item5ListInfo2: "Cloud IDE管理公有云token,开发者使用各种资源更加方便",
  item5ListTitle3: "开发中的灰度测试",
  item5ListInfo3: "本地环境的开发测试,使用Cloud IDE后使用云环境进行开发中的灰度测试",
  item5ListTitle4: "开源软件的POC",
  item5ListInfo4: "开源软件使用预置云环境,一键建立开发环境,离你的开发者更近一步",
  item5ListTitle5: "程序员面试中的项目考核",
  item5ListInfo5: "技术面试中使用项目的模式进行快速的沟通考核,了解你需要的候选人能力",
  item6WechatText:"微信扫码加入",
  item6FlybookText:"飞书扫码加入",
  item6UserText:"GeekCode用户群",
  item6Title: "随时随地协同开发",
  item6SubTitle: "现在可以在线体验、下载社区版产品,输入邮箱免费体验。",
  inputPlaceholder: "请输入你的邮箱",
  emailSubmit: '立即体验',
  emailRules: "邮箱格式不正确",
  submitSuccess: "提交成功！",
  footerTab1: "隐私和安全",
  footerTab2: "使用条款",
  footerTab3: "法律信息",
  copyRight: " 深圳阿拉丁五人科技有限公司",
  pricingTitle: "简单实惠的定价",
  pricingFooterText: "去体验吧～",
  pricingMoUnit: "/月",
  pricingUserMoUnit: "/人/月",
  pricingSuffix: "起",
  pricingCard0Num: "￥0",
  pricingCard0Title: "开源项目/教育用户",
  pricingCard0SubTitle: "开始云端编程",
  pricingCard0List0: "1个工作空间",
  pricingCard0List1: "免费的存储（2GB）",
  pricingCard0List2: "1 核 vCPU",
  pricingCard0List3: "2G 内存",
  pricingCard0List4: "镜像管理",
  pricingCard0List5: "可以协作分享的工作空间",
  pricingCard1Num: "￥150",
  pricingCard1Title: "专业",
  pricingCard1SubTitle: "开发效率提升",
  pricingCard1List0: "无限的工作空间",
  pricingCard1List1: "跨地区网络速度提升",
  pricingCard1List2: "5GB+ 存储空间 （每个工作空间）",
  pricingCard1List3: "4GB+ 内存（每个工作空间）",
  pricingCard1List4: "2核+ vCPU （每个工作空间）",
  pricingCard1List5: "至少160小时运行时间",
  pricingCard1List6: "效率更高的主机",
  pricingCard2Num: "￥735",
  pricingCard2Title: "企业",
  pricingCard2SubTitle: "更安全、更有力、私有化部署",
  pricingCard2List0: "无限的工作空间",
  pricingCard2List1: "团队成员管理",
  pricingCard2List2: "开发过程审计",
  pricingCard2List3: "多云跨集群部署",
  pricingCard2List4: "私有化部署",
  pricingCard2List5: "跨地区网络速度提升",
}