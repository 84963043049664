import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { MobHome} from '../pages'

const Routes = ({locale}) => (
  <Switch>
    <Route exact path="/" component={()=><MobHome locale={locale}/>} />
    <Redirect from="*" to="/" />
  </Switch>

)

export default Routes
