import React from "react";
import './planCard.less'
import {FormattedMessage } from 'react-intl';
const PlanCard = ({items,listId}) => {
return(
  <FormattedMessage id={'item5ListTitle'+listId}>
   { text=>(
  <div className="card-container" style={(/^[a-zA-Z]/g.test(text) && document.body.clientWidth > 1024)?{height:"369px"}:{height:'auto'}}>
    <div className="icon-box">
      <img src={items.icon} alt={text} />
    </div>
    <h4>{text}</h4>
    <div className="desc">
     <p><FormattedMessage id={'item5ListInfo'+listId}/></p>
    </div>
  </div>
    )}

  </FormattedMessage>
)
}
export default PlanCard