import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App';
import {geekcodeGit} from './common'
const locHash = window.location.hash
if(locHash && locHash.includes('github.com')){
  window.location.replace(`${geekcodeGit}${locHash}`);
}
ReactDOM.render(
    <Router>
    <App />
    </Router>
  ,document.getElementById('root')
);
