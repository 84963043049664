import React,{useEffect} from 'react'
import { FormattedMessage } from 'react-intl'
const List = ({datas,onDotsIdx,checkId,progressW,autoIdx})=>{
  useEffect(() => {

  }, [progressW,autoIdx])
return (
<ul className='side-dots-list'>
{
  datas && datas.map((item,idx)=>{
    return <li  className={`dots-item ${checkId === idx ? 'dots-active' : ''}`} onClick={()=>onDotsIdx(idx)} key={idx+'side'}><FormattedMessage id={'carouse'+idx}/></li>
  })
}
  <li className='dots-item progress-bg' style={{width:`${progressW}%`,top:`${autoIdx*76}px`,background:`${progressW <= 0 ?'none':'rgba(35, 80, 240, .5)' }`}}></li>
</ul>
)
}
export default List