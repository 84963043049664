import React, {useEffect, useState} from 'react'
import PCLayout from './components/Layout/Pc'
import MobLayout from './components/Layout/Mobile'
import './App.less';
import {IntlProvider} from 'react-intl';
import zhCN from './locales/zh-CN';
import enUS from './locales/en-US';

const langMap = {'zh': zhCN, 'en': enUS};
// JavaScript 的媒体查询 
const mqlMedia = window.matchMedia('(orientation: portrait)')

function onMatchMediaChange(mql = window.matchMedia('(orientation: portrait)')) {
    if (mql.matches) {
        //竖屏
        return 'portrait'
    } else {
        //横屏
        return 'horizontal'
    }
}

// 输出当前屏幕模式
const getUiMode = (uiMode = '', mql) => {
    if (uiMode) return uiMode
    if (!('onorientationchange' in window)) return 'pc'
    let status = onMatchMediaChange(mql)
    let width = status === 'portrait' ? Math.min(window.innerWidth, window.innerHeight) : Math.max(window.innerWidth, window.innerHeight)
    if (width > 1040) return 'pc'
    return 'mobile'
}
const getIsPcMode = (uiMode) => uiMode === 'pc'

const App = () => {
    let [uiMode, setUiMode] = useState(getUiMode())
    let [isPCMode, setIsPCMode] = useState(getIsPcMode(uiMode))
    // 首次访问，以浏览器默认语言为主，如手动点击语言切换后。刷新网页以sessionStorage的为主。否则默认为zh
    // let language = sessionStorage.getItem('locale') || window.navigator.language.toLowerCase().slice(0,2) || 'en'
    let language = 'en'
    let [locale, setLocale] = useState(language)
    useEffect(() => {
        const changeUiMode = (mql) => {
            let newUiMode = getUiMode('', mql)
            if (newUiMode !== uiMode) {
                setUiMode(newUiMode);
                setIsPCMode(getIsPcMode(newUiMode))
            }
        }
        mqlMedia.addListener(changeUiMode)
        return () => {
            mqlMedia.removeListener(changeUiMode)
        }
    }, [uiMode])

    return (
        <IntlProvider locale={locale} messages={langMap[locale]}>
            <div className="container__box">
                {isPCMode ? <PCLayout setLocale={setLocale} locale={locale}/> :
                    <MobLayout locale={locale} setLocale={setLocale}/>}
            </div>
        </IntlProvider>
    )
}

export default App
