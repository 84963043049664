import React from "react";
import "./pc.less";
// import { Divider } from "antd";
import ConetentHeader from "../../components/ConetentHeader";
import EmailForm from "../../components/EmailForm";
import PlanList from "../../components/Card/PlanList";
import ProductCharacteristics from "../../components/ProductCharacteristics";
import Price from "../../components/Price";
import { PcCarousel } from '../../components/Carousels/CarouselsBox'
import  VideoBox  from '../../components/Video'

import { FormattedMessage } from "react-intl";
import * as icons from "../../assets/icons";
const Home = ({ locale }) => {
  return (
    <div className="content">
      <div className="top-line-bg">
        <img src={icons.topLineBg} alt="topLineBg" />
      </div>
      <div className="center-box">
        {/* item1 */}
        <div className="content-item1-pc">
          <ConetentHeader locale={locale} />
        </div>
        {/* item2 */}
        <div className="content-item2-pc">
          <div className="line-bg">
            <img src={icons.lineBg} alt="lineBg" />
          </div>
          <div className="item2-title">
            <h3>
              <FormattedMessage id="item3Title" defaultMessage="此网站在GeekCode上完成开发和测试" />
            </h3>
            <p>
              <FormattedMessage id="item3SubTitle" />
            </p>
          </div>
          <div className="item2-card-list">
            <ProductCharacteristics />
          </div>
        </div>
        {/* item4 */}
        <div className="content-item4-pc">
        {/* <h3 className="item4-title"><FormattedMessage id="item4Title" defaultMessage="为什么用GeekCode.Cloud IDE" /></h3> */}
        <div className="item4-carousel-pc">
          <PcCarousel locale={locale}/>
        </div>
      </div>
        {/* item5 */}
        <div className="content-item5">
          <div className="item5-title">
            <h3>
              <FormattedMessage id="item5Title" defaultMessage="使用场景" />
            </h3>
            <p>
              <FormattedMessage id="item5SubTitle" />
            </p>
          </div>
          <div className="item5-card-list">
            <PlanList />
          </div>
        </div>
        {/* 视频框 */}
        <div className="content-item8-pc">
          <VideoBox locale={locale}/>
        </div>
        {/* item7 定价*/}
        <div className="content-item7-background" id="item7">
          <div className="content-item7">
            <img src={icons.priceBg} alt="background" className="priceBg" />
            <div className="item7-title">
              <h3>
                <FormattedMessage id="pricingTitle" defaultMessage="简单实惠的定价"  />
              </h3>
            </div>
            <div className="item7-card-list">
              <Price />
            </div>
            <div className="bottom-text">
              <FormattedMessage id="pricingFooterText" defaultMessage="去体验吧~" />
            </div>
          </div>
        </div>
        {/* 箭头 */}
        <div className="arrow">
          <img src={icons.arrow} alt="arrow" />
        </div>
        {/* item6 */}
        {/* <Divider style={{ borderTopColor: "#33363A" }} /> */}
        <div className="content-item6" id="item6">
          <div className="item6-code-box">
            <div>
              <div className="img-border">
                <img src={icons.wechatCode}/>
              </div>
              <p> 
                <FormattedMessage
                  id="item6WechatText"
                  defaultMessage="微信扫码加入"
                /></p>
              <p><FormattedMessage id="item6UserText" defaultMessage="GeekCode用户群" /></p>
            </div>
            {/*<div>*/}
            {/*  <div className="img-border">*/}
            {/*  <img src={icons.feishuCode}/>  */}
            {/*  </div>*/}
            {/*  <p><FormattedMessage id="item6FlybookText" defaultMessage="飞书扫码加入" /></p>*/}
            {/*  <p><FormattedMessage id="item6UserText" defaultMessage="GeekCode用户群" /></p>*/}
            {/*</div>*/}
          </div>
          <div className="item6-email-box">
            <div className="item6-text">
              <h3>
                <FormattedMessage
                  id="item6Title"
                  defaultMessage="随时随地协同开发"
                />
              </h3>
              <p>
                <FormattedMessage id="item6SubTitle" />
              </p>
            </div>
            <div className="item6-form">
              <EmailForm formLayout={"inline"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
