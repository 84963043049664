import React from "react";
import PlanCard from './PlanCard'
import * as icons from '../../assets/icons'
let listArr = [
  {
    icon: icons.planIcon0,
    title: '项目依赖多个环境',
    desc: '当开发过程中涉及多个依赖的运行环境（x86,arm架构等）不需要频繁切换term,在不同环境下编译你的binary'
  },
  {
    icon: icons.planIcon1,
    title: '前后端工程师无缝沟通',
    desc: 'Dev URL 开发中不再需要测试服务器，远程协同开发无缝连接'
  },
  {
    icon: icons.planIcon2,
    title: '自测时管理公有云token',
    desc: 'Cloud IDE管理公有云token,开发者使用各种资源更加方便'
  },
  {
    icon: icons.planIcon3,
    title: '开发中的灰度测试',
    desc: '本地环境的开发测试,使用Cloud IDE后使用云环境进行开发中的灰度测试'
  },
  {
    icon: icons.planIcon4,
    title: '开源软件的POC',
    desc: '开源软件使用预置云环境,一键建立开发环境,离你的开发者更近一步'
  },
  {
    icon: icons.planIcon5,
    title: '程序员面试中的项目考核',
    desc:'技术面试中使用项目的模式进行快速的沟通考核,了解你需要的候选人能力'
  },
]
const PlanList = () => {
  return (
    <>
      {
        listArr.map((item, idx) => {
          return <PlanCard key={idx + 'plan'} items={item} listId={idx} />
        })
      }
    </>
  )
}
export default PlanList