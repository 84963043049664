import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { PcHome} from '../pages'

const Routes = ({locale}) => (

  <Switch>
    <Route exact path="/" component={()=><PcHome locale={locale}/>}/>
    <Redirect from="*" to="/" />
  </Switch>
)

export default Routes
