import {FormattedMessage } from 'react-intl';
import './footer.less'
const Footer = () => {
  return (
    <div className='footer'>
      <div className='footer-container grid'>
        <div className='menu-list'>
          <a className='doc-btn btn-style' target='_blank' rel="noreferrer" href="/"><FormattedMessage id='footerTab1'/></a>
          <a className='doc-btn btn-style' target='_blank' rel="noreferrer" href="/"><FormattedMessage id='footerTab2'/></a>
          <a className='doc-btn btn-style' target='_blank' rel="noreferrer" href="/"><FormattedMessage id='footerTab3'/></a>
          <a className='doc-btn btn-style' target='_blank' rel="noreferrer" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=2022119416号-4">粤ICP备2022119416号-4</a>
        </div>
        <p className='copy-right'>Copyright &copy;<FormattedMessage id='copyRight'/></p>
      </div>
    </div>)
}
export default Footer